import './App.css';
import Banner from './components/Banner';
import Navbar from './components/Navbar';
import Row from './components/Row';
import requests from './requests'

function App() {
  
  return (
        <div className="App">

    < Navbar/>
    
< Banner/>


      <Row title="NETFLIX movies" fetchUrl={requests.fetchNetflixOriginals} isLargeRow/>
       <Row title="Trending Now" fetchUrl={requests.fetchTrending } />
      <Row title="Top Rated" fetchUrl={requests.fetchTopRated } />
      <Row title="Action Movies" fetchUrl={requests.fetchActionMovies } />
      <Row title="Comedy Movies" fetchUrl={requests.fetchComedyMovies } />
      <Row title="Horror Movies" fetchUrl={requests.fetchHorrorMovies } />
      <Row title="Romantic Movies" fetchUrl={requests.fetchRomanceMovies } />
      <Row title="Documentaries" fetchUrl={requests.fetchDocumentaries} /> 
      <footer>
        <div>
          <div className="footer h-23 clearfix text-white" style={{ "backgroundColor": '#34Ff23' }}></div>
          <nav className="navbar navbar-expand-lg bg-dark navbar-dark text-white d-flex justify-content-around ">
            <div>© TSBF TV Copyright 2022. All Rights Reserved </div>
            <div>Powered by  &nbsp;<a style={{ color: 'yellow', textDecoration: 'none' }} href="https://superjcybs.com">SUPERJCYBS</a></div>
            <span ><a className='px-3 py-1' href='https://www.facebook.com/superjcybs' style={{ backgroundColor: 'white', color: 'blue', fontWeight: 'bold', borderRadius: '50%', fontSize: '25px', width: '30px', height: '30px', textDecoration: 'none' }}>f</a></span>
          </nav>
        </div>
      </footer>
    </div>
  );
}

export default App;
