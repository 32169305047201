import React, { useState, useEffect } from 'react'
import axios from '../axios'
import './Row.css'
// import Youtube from 'react-youtube'
import movieTrailer from 'movie-trailer'
import ReactPlayer from 'react-player'



const base_url = "https://image.tmdb.org/t/p/original/"

const Row = ({ title, fetchUrl, isLargeRow }) => {

    const [movies, setMovies] = useState([])
    const [trailerUrl, setTrailerUrl] = useState("")

    useEffect(() => {
        async function fetctData() {
            const request = await axios.get(fetchUrl)
            //  console.table('recieved movies',request.data.results)
            setMovies(request.data.results)
            return request
        }
        fetctData()

    }, [fetchUrl])

    // const opts = {
    //     height: "390",
    //     width: "100%",
    //     // host: 'https://www.youtube.com/iframe_api',
    //     playerVars: {
    //         autoplay: 1,
    //         origin: 'http://localhost:3000', 
    //         // origin: 'https://www.youtube.com',
    //     },
    // }

    const handleClick = (movie) => {
        if (trailerUrl) {
            // console.log('movie clicked')
            // setTrailerUrl('')
        } else {
            // movieTrailer('Oceans Eleven', { year: '1960', multi: false })
            movieTrailer( movie?.title )
                .then(response =>
                    // console.log(response)
                    setTrailerUrl(response)
                    )
            // movieTrailer(movie?.name || "")
            //     .then(url => console.log(url)
                //     {
                //     const urlParams = new URLSearchParams(URL(url).search);
                //     setTrailerUrl(urlParams.get('v'))
                // }    
                // )
                .catch((error) => console.log(error))
        }
    }
    console.log('setted',trailerUrl)
    
    return (
        <div className='row'>
            <h2>{title}</h2>
            <div className='row__posters'>
                {movies.map(movie => (
                    <img
                        src={`${base_url}${isLargeRow ? movie.poster_path : movie.backdrop_path}`}
                        alt={movie.title}
                        className={`row__poster ${isLargeRow && "row__posterLarge"}`}
                        onClick={() => handleClick(movie)}
                        key={movie.id} />
                ))}
            </div>
            {/* {trailerUrl && <Youtube autoplay videoId={trailerUrl} opts={opts} />} */}
            {/* {trailerUrl && <ReactPlayer playing controls loop width='100%' height='390px' url='https://www.youtube.com/watch?v=OjWLU2mMZyc' />} */}
            {trailerUrl &&
                <ReactPlayer playing controls
                width='100%' height='390px'
                url={trailerUrl}
                // url={[
                //     `${trailerUrl}`,
                //     'https://www.youtube.com/watch?v=jNgP6d9HraI'
                // ]}
                config={{ facebook: { appId: '686871576266039' } }}
                />
            }
        </div>
    )
}

export default Row