import React, {useState} from 'react'
import './Navbar.css'

const Navbar = () => {

const [show, handleShow] = useState(false)

  window.onscroll = function () { scrollFunction() }
  function scrollFunction() {
    if (document.documentElement.scrollTop > 40 || document.documentElement.scrollTop > 40) {
      handleShow(true);
    } else {
      handleShow(false);
    }
  }

  return (
    <div className={`nav ${show && "nav__black"}`}>
      <span className='nav__logo'>WATCH LIVE TV</span>
      <img className='nav__avatar' src='/assets/images/logo2.png' alt='main logo'/>
    </div>
  )
}

export default Navbar